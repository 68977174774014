<!--
 * @Date: 2021-03-20 13:35:20
 * @LastEditors: frank
 * @LastEditTime: 2021-05-10 15:00:45
 * @FilePath: /shop_frontend/src/views/memberList/addPoints.vue
 * @Description: Description
-->
<template>
  <section class="addPoints">
    <a-modal
      title="修改积分"
      :visible="visible"
      @ok="handleOk"
      @cancel="cancelDodal"
      width="50%"
    >
      <div>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">当前积分</a-col>
          <a-col span="19" offset="1">{{ currentRecord.points || 0 }}</a-col>
        </a-row>
        <a-row style="margin-bottom: 20px" type="flex" align="middle">
          <a-col span="4">增加积分</a-col>
          <a-col span="19" offset="1">
            <a-input class="w220" v-model="addForm.num"></a-input>
            <span>(*当积分为正时，增加积分；积分为负时，减少积分*)</span>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle">
          <a-col span="4">增加备注</a-col>
          <a-col span="19" offset="1">
            <a-input v-model="addForm.remark"></a-input>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </section>
</template>

<script>
export default {
  name: "addPoints",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentRecord: {
      type: Object,
    },
  },
  data() {
    return {
      addForm: {},
    };
  },
  components: {},
  computed: {},
  watch: {
    visible(nVal) {
      if (nVal) {
        this.addForm = {};
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleOk() {
      this.$axios
        .post(`/members/${this.currentRecord.id}/add_integral/`, {
          points: this.addForm.num,
          remark: this.addForm.remark,
        })
        .then(() => {
          this.$message.success("操作成功");
          this.$parent.pointsVisible = false;
          console.log(this.$parent);
          if (this.$parent.getDetail) {
            this.$parent.getDetail()
          } else {
            this.$parent.initTable();
          }
        });
      this.$parent.pointsVisible = false;
    },
    cancelDodal() {
      this.$parent.pointsVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
</style>
